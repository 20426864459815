import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gui_h1 = _resolveComponent("gui-h1")!

  return (_openBlock(), _createBlock(_component_gui_h1, null, {
    default: _withCtx(() => [
      _createTextVNode("BackOffice")
    ]),
    _: 1
  }))
}